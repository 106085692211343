import {createMuiTheme} from "@material-ui/core";

export const appTheme = createMuiTheme({
    palette: {
        //type: 'dark',
    },
    typography: {
        fontFamily: [
            'Ubuntu',
            'sans-serif',
        ].join(','),
    },
});

export const appStyles = {
    root: {
        flexGrow: 1,
        marginLeft: appTheme.spacing(2),
        marginRight: appTheme.spacing(2),
    },
    alignRight: {
        float: 'right',
    },
    appBar: {
        position: 'fixed',
        menuButton: {
            marginRight: appTheme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    },
    eventStatusCancelled: {
        color: appTheme.palette.error.main,
    },
    eventStatusCompleted: {
        color: appTheme.palette.success.main,
    },
    layout: {
        width: 'auto',
        marginLeft: appTheme.spacing(2),
        marginRight: appTheme.spacing(2),
        [appTheme.breakpoints.up(600 + appTheme.spacing(6) * 2)]: {
            marginLeft: appTheme.spacing(6),
            marginRight: appTheme.spacing(6),
        },
        [appTheme.breakpoints.up(800 + appTheme.spacing(6) * 2)]: {
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    calendarLayout: {
        width: 'auto',
        marginLeft: appTheme.spacing(2),
        marginRight: appTheme.spacing(2),
    },
    pageTitle: {
        color: 'default',
        padding: appTheme.spacing(2),
    },
    calendarPaper: {
        marginTop: appTheme.spacing(1),
        marginBottom: appTheme.spacing(3),
        padding: appTheme.spacing(1),
    },
    paper: {
        marginTop: appTheme.spacing(1),
        marginBottom: appTheme.spacing(3),
        padding: appTheme.spacing(3),
        [appTheme.breakpoints.up(600 + appTheme.spacing(3) * 2)]: {
            marginTop: appTheme.spacing(2),
            marginBottom: appTheme.spacing(6),
            padding: appTheme.spacing(4),
        },
    },
    grid: {
      marginTop: appTheme.spacing(2),
      marginBottom: appTheme.spacing(2),
    },
    dialogTitle: {
        margin: 0,
        padding: appTheme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: appTheme.spacing(1),
        top: appTheme.spacing(1),
        color: appTheme.palette.grey[500],
    },
    flightPaper: {
        height: "100%"
    },
    auditContainer: {
        paddingLeft: appTheme.spacing(3),
        paddingRight: appTheme.spacing(3),
        paddingTop: appTheme.spacing(3),
        paddingBottom: appTheme.spacing(3),
    },
    flightContainer: {
        paddingLeft: appTheme.spacing(3),
        paddingRight: appTheme.spacing(3),
        paddingBottom: appTheme.spacing(3),
    },
    userContainer: {
        paddingTop: appTheme.spacing(2),
        paddingLeft: appTheme.spacing(3),
    },
    fabEdit: {
        backgroundColor: appTheme.palette.warning.light,
        '&:hover': {
            backgroundColor: appTheme.palette.warning.main,
        }
    },
    fabAdd: {
        backgroundColor: appTheme.palette.success.light,
        '&:hover': {
            backgroundColor: appTheme.palette.success.main,
        }
    },
    fabDelete: {
        backgroundColor: appTheme.palette.error.light,
        '&:hover': {
            backgroundColor: appTheme.palette.error.main,
        }
    },
    fab: {
        float: 'right',
        right: appTheme.spacing(-1),
        top: appTheme.spacing(-1),
        marginBottom: appTheme.spacing(-2),
        marginLeft: appTheme.spacing(1),
    },
    hoverCell: {
        paddingRight: appTheme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: appTheme.spacing(-2),
    },
    hoverRow: {
        "& .hidden-control": {
            display: "none",
        },
        "&:hover .hidden-control": {
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
        },
        "& .will-hide": {
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
        },
        "&:hover .will-hide": {
            display: "none",
        },
        "& .no-hide": {
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            valign: "middle",
        },
    },
    table: {
        tableLayout: "fixed",
    },
    timePicker: {
        maxWidth: "10em",
        paddingRight: appTheme.spacing(1),
    },
    offset: appTheme.mixins.toolbar,
    noPadding: {
        padding: 0,
    },
};
