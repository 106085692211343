import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import { withAuthorization } from '../Session';
import {
    Grid,
    Hidden,
    withStyles,
} from "@material-ui/core";
import {appStyles} from "../../styles";
import {addHours} from "date-fns";
import {ROUTES} from "../../App";
import {isMember, ROLES, SORTIE_LOCKS} from "../../constants";
import {compose} from "recompose";
import CrewMembers from "../Flights/crewMembers";
import FlightNotes from "../Flights/flightTextArea";
import TripData from "./tripData";
import FlightTimes from "../Flights/flightTimes";
import Rons from "./rons";
import AuditView, {AuditableChange} from "../Audit";

const TripPage = (props) => {
    const {classes, history, firebase, authUser} = props;
    const [loading, setLoading] = useState(true);
    // Sortie Details
    const {tripId} = useParams();
    const [trip, setTrip] = useState({});
    const [notes, setNotes] = useState("");
    const [editNotes, setEditNotes] = useState(false);
    const [userLock, setUserLock] = useState(false);
    const [schedLock, setSchedLock] = useState(false);
    const [adminLock, setAdminLock] = useState(false);

    const canEdit = !adminLock && (!schedLock || authUser.role === ROLES.ADMIN) && authUser.role > ROLES.MEMBER;

    const setValues = (doc) => {
        const {xcd, notes} = doc.data();
        setTrip(doc.data());
        setNotes(notes);
        if (new Date(xcd.takeoffTime) < addHours(new Date(), SORTIE_LOCKS.USER)) {
            setUserLock(true);
        }
        if (new Date(xcd.takeoffTime) < addHours(new Date(), SORTIE_LOCKS.SCHED)) {
            setSchedLock(true);
        }
        if (new Date(xcd.takeoffTime) < addHours(new Date(), SORTIE_LOCKS.ADMIN)) {
            setAdminLock(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        return firebase
            .trip(tripId).onSnapshot(doc => {
                if (doc.exists) {
                    setValues(doc);
                } else {
                    history.push(ROUTES.TRIP_BOARD.path);
                }
            });
    }, [firebase, history, tripId]);

    const onCancelEdit = () => {
        firebase.trip(tripId).get().then((doc) => {
            if (doc.exists) {
                setValues(doc);
            }
        });
    }

    const onSaveNotes = () => {
        const delta = {
            notes: notes
        }
        AuditableChange(authUser.uid, firebase.trip(tripId), trip, delta,
            "Edited the notes")
    }

    return (
        <Hidden xsUp={loading}>
            <Grid container
                className={classes.grid}
                spacing={3}
                justify="center"
            >
                <Grid item xs={12}>
                    <TripData
                        tripId={tripId}
                        trip={trip}
                        editHidden={!canEdit}
                        />
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                    <FlightTimes
                        title={"Departure"}
                        fieldName={"xcd"}
                        flightHandle={firebase.trip(tripId)}
                        flight={trip.xcd}
                        editHidden={!canEdit}
                    />
                </Grid>
                <Grid item md={3} sm={3} xs={6}>
                    <FlightTimes
                        title={"Return"}
                        fieldName={"xcr"}
                        flightHandle={firebase.trip(tripId)}
                        flight={trip.xcr}
                        editHidden={!canEdit}
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <CrewMembers
                        eventHandle={firebase.trip(tripId)}
                        isFlight={false}
                        userLock={userLock}
                        schedLock={schedLock}
                        adminLock={adminLock}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Rons
                        tripId={tripId}
                        schedLock={schedLock}
                        adminLock={adminLock}
                        rons={trip.rons}
                        departureDate={trip.xcd ? trip.xcd.takeoffTime : new Date()}
                        returnDate={trip.xcr ? trip.xcr.landTime : new Date()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FlightNotes
                        notes={notes}
                        setNotes={setNotes}
                        editNotes={editNotes}
                        setEditNotes={setEditNotes}
                        onCancelNotes={onCancelEdit}
                        onSaveNotes={onSaveNotes}
                        canEdit={canEdit}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AuditView auditTrail={trip.auditTrail}/>
                </Grid>
            </Grid>
        </Hidden>
    );
}

export default compose(
    withStyles(appStyles),
    withAuthorization(isMember),
)(TripPage);